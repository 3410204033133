
/*
 * DIMDI
 * Scripts for slider initialization and modification
 */

var DimdiSearch = function(jQ) {

	var lastPrefix;
	var PREFIX_MINLENGTH = 3;
	var MAX_SUGGESTION_COUNT = 8;

	function getSuggestions(event, elem){
		var inputWrapperId = elem.getAttribute("data-id");
		var inputWrapper = document.getElementById(inputWrapperId);
		if(event.keyCode == 13 || event.keyCode == 40 || event.keyCode == 38){
			return // Keys are used for special purposes, ignore them
		}
		var minLength = PREFIX_MINLENGTH;
		var prefix = getInput(elem);
		var src = 2;
		if(lastPrefix === prefix){
			return;
		}
		else {
			lastPrefix = prefix;
		}
		if(prefix.length >= minLength){ // only search for suggestions, if minimum length is met
			var xmlhttp = new XMLHttpRequest();
			var ajaxpath = inputWrapper.getAttribute("data-ajax");
			var sitepath = inputWrapper.getAttribute("data-path");
			var locale = inputWrapper.getAttribute("data-locale");
			var url = ajaxpath + "?prefix=" + encodeURIComponent(prefix) + "&path=" + sitepath+"&loc="+locale;

			xmlhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					var result = JSON.parse(this.responseText);
					if(result.length > 0){
						var array = processResult(result);
						showSuggestions(inputWrapper, array);
					}
					else {
						var sugWrapper = inputWrapper.getElementsByClassName('suggestions-wrapper');
						hideSuggestions(sugWrapper);
					}
				}
			};
			xmlhttp.open("GET", url, true);
			xmlhttp.send();
		}
		else {
			var sugWrapper = inputWrapper.getElementsByClassName('suggestions-wrapper')[0];
			removeSuggestions(sugWrapper);
		}
	}
	
	function processResult(res){
		var processed = new Array();
		for(var i = 0; i < res.length; i++) {
			var resObj = res[i];
			for (var key in resObj) {
				if (Object.prototype.hasOwnProperty.call(resObj, key)) {
					var val = resObj[key];
					// see if key exists already in processed
					if (Object.prototype.hasOwnProperty.call(processed, key)) {
						// it exists, add counts
						var tempCount = processed[key];
						processed[key] = tempCount + val;
					}
					else {
						// it does not exists, just insert it
						processed[key] = val;
					}
				}
			}
			
		}
		
		processedResult = new Array();
		var index = 0;
		for (var key in processed) {
			if (Object.prototype.hasOwnProperty.call(processed, key)) {
				var val = processed[key];
				processedResult[index++] = {"value": key, "count": val};
			}
		}
		
		// sort the result array
		processedResult.sort(function(a, b){
			return b.count - a.count;
		});
		
		return processedResult
	}

	function showSuggestions(elem, arr) {
		var sugWrapper = elem.getElementsByClassName('suggestions-wrapper')[0];
		removeSuggestions(sugWrapper);
		for(var i = 0; i < Math.min(arr.length, MAX_SUGGESTION_COUNT); i++) {
			addSuggestion(elem, sugWrapper, arr[i].value);
		}
		sugWrapper.style.display = "block";
		sugWrapper.className = "suggestions-wrapper visible";
	}

	function hideSuggestions(sugWrapper) {
		sugWrapper.style.display = "none";
		sugWrapper.className = "suggestions-wrapper";
	}
	
	function addSuggestion(elem, wrapper, value){
		var ul = wrapper.getElementsByClassName('suggestion-list')[0];
		var li = document.createElement("li");
		li.addEventListener('click', function(){
			var input = elem.getElementsByClassName("autocomplete-input")[0];
			useSuggestion(input, wrapper, this);
		});
		li.className = "suggestion-entry";
		li.innerText = value;
		ul.appendChild(li);
	}
	
	function removeSuggestions(wrapper){
		wrapper.getElementsByClassName('suggestion-list')[0].innerHTML = "";
	}
	
	function useSuggestion(input, sugWrapper, elem){
		input.value = elem.textContent;
		hideSuggestions(sugWrapper);
	}
	
	function useActiveSuggestion(elem){
		var formname = elem.getAttribute("data-form");
		var inputWrapperId = elem.getAttribute("data-id");
		var inputWrapper = document.getElementById(inputWrapperId);
		var activeItem = inputWrapper.getElementsByClassName('suggestion-entry active');
		if(activeItem.length > 0){
			activeItem = activeItem[0];
			var input = inputWrapper.getElementsByClassName("autocomplete-input")[0];
			input.value = activeItem.textContent;
			var sugWrapper = inputWrapper.getElementsByClassName('suggestions-wrapper');
			hideSuggestions(sugWrapper);
		} else {
			document.forms[formname].submit();
		}
	}
	
	function getInput(elem){
		var input = elem.value;
		return input;
	}
	
	function nextSuggestion(elem){
		var inputWrapperId = elem.getAttribute("data-id");
		var inputWrapper = document.getElementById(inputWrapperId);
		var sugWrappers = inputWrapper.getElementsByClassName('suggestions-wrapper visible');
		if(sugWrappers.length == 0){
			return; // there is no suggestionbox, do nothing
		}
		// check if any entry is active
		var activeItem = inputWrapper.getElementsByClassName('suggestion-entry active');
		var nextItem;
		if(activeItem.length > 0){
			nextItem = activeItem[0].nextElementSibling; // get next item and activate it
		}
		else {
			nextItem = inputWrapper.getElementsByClassName('suggestion-entry')[0];
		}
		deselectSuggestions();
		selectSuggestion(nextItem);
	}
	
	function previousSuggestion(elem){
		var inputWrapperId = elem.getAttribute("data-id");
		var inputWrapper = document.getElementById(inputWrapperId);
		var sugWrappers = inputWrapper.getElementsByClassName('suggestions-wrapper visible');
		if(sugWrappers.length == 0){
			return; // there is no suggestionbox, do nothing
		}
		// check if any entry is active
		var activeItem = inputWrapper.getElementsByClassName('suggestion-entry active');
		var prevItem;
		if(activeItem.length > 0){
			prevItem = activeItem[0].previousElementSibling; // get next item and activate it
		}
		else {
			var items = inputWrapper.getElementsByClassName('suggestion-entry');
			prevItem = items[items.length - 1];
		}
		deselectSuggestions();
		selectSuggestion(prevItem);
	}
	
	function selectSuggestion(elem){
		if(typeof elem !== "undefined" && elem != null){
			elem.className="suggestion-entry active";
		}
	}
	
	function deselectSuggestions(){
		var wrappers = document.getElementsByClassName('inputwrapper');
		for(var j = 0; j < wrappers.length; j++){
			var sugItems = wrappers[j].getElementsByClassName('suggestion-entry');
			for(var i = 0; i < sugItems.length; i++){
				sugItems[i].className="suggestion-entry";
			}
		}
	}

	function init(){
		var inputFields = document.getElementsByClassName("autocomplete-input");
		for(var i = 0; i < inputFields.length; i++){
			var inputField = inputFields[i];
			inputField.addEventListener('keyup', function(event){
				getSuggestions(event, this);
			})
			inputField.addEventListener('keydown', function(event) {
				if(event.keyCode == 38) { // UP
					previousSuggestion(this);
					event.preventDefault();
				}
				else if(event.keyCode == 40) { // DOWN
					nextSuggestion(this);
					event.preventDefault();
				}
				else if(event.keyCode == 13) { // ENTER
					useActiveSuggestion(this);
					event.preventDefault();
				}
			});
		}
		
		if(inputFields.length > 0){
			document.addEventListener('mousemove', function(event){
				deselectSuggestions();
			});
			
			document.addEventListener('mouseup', function(event){
				var sugWrappers = document.getElementsByClassName('suggestions-wrapper visible');
				if(sugWrappers.length > 0 && event.target.className !== "autocomplete-input"){
					for(var i = 0; i < sugWrappers.length; i++){
						hideSuggestions(sugWrappers[i]);
					}
				}
			});
		}
	}
	
	return {
		init: init
	}

}(jQuery);
