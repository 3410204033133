
/*
 * DIMDI
 * Scripts for slider initialization and modification
 */

var DimdiNavigation = function(jQ) {
	
	function copyHeadlinks(){
		var links = $(".ap-header .dimdi-links-hf ul").clone();
		links.children().addClass("headlink");
		links.eq(1).children().addClass("icon lang");
		links.children().appendTo(".head-navbar div.navbar-collapse div.container #navigation > ul");
		$(".head-navbar div.navbar-collapse div.container #navigation > ul > .hoverSelector").remove();
		$(".head-navbar div.navbar-collapse div.container #navigation > ul > li.divider").remove();
	}
	
	function backToTopHandler(){
		var button = $("#topcontrol");
		button.addClass("small");
	}
	
	function flipRightmostNavDropdowns(){
		var dropdowns = $("header ul.nav.navbar-nav > li.dropdown");
		var len = $("ul.nav.navbar-nav > li.dropdown").length;
		dropdowns.eq(len-1).addClass("flip");
		dropdowns.eq(len-2).addClass("flip");
	}
	
	function init(){
		copyHeadlinks();
		backToTopHandler();
		flipRightmostNavDropdowns();
	}
	
	return {
		init: init
	}

}(jQuery);
