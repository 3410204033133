
/*
 * DIMDI Newsletter functions (will be moved to Apollo sooner or later)
 */

var DimdiNewsletter = function(jQ) {
	
	function init(){
		initInfomailForm();
	}
	
	function validate(formname){
		var form = document.forms[formname];
		var mailValue = form["email"].value;
		var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\[\]\.,;:\s@\"]+\.)+[^<>()\[\]\.,;:\s@\"]{2,})$/i;
		var valid = re.test(mailValue);
		if(valid){
			form.submit();
			$(".email-format-error").hide();
		}
		else {
			$(".email-format-error").show();
		}
	}
	
    function buildFormParameters() {
    	var checkboxes = $(".ap-newsletterform .mailinglist input.single:checked");
    	var hiddenField = $(".ap-newsletterform .mailinglist input.single.hidden");
    	var result = "";
    	var itemCount = checkboxes.length;
    	// if multiple lists, get all checked names
    	if(itemCount > 0){
        	for(var i = 0; i < itemCount; i++){
        		var checkbox = checkboxes.eq(i);
            	result += checkbox.attr("name");
        		if(i < itemCount - 1){
        			result += ";";
        		}
        	}
    	}
    	// if only one list, just take this
    	else {
    		result = hiddenField.attr("name");
    	}
    	$("#field-lists").val(result);
    }
    
    function selectAllMailinglists(elem){
    	console.log("Trara");
    	var checkboxes = $('input.single');
    	var status = $(elem).prop('checked');
    	if(status){
    		$("ul.mailinglist").addClass("disabled");
    	} else {
    		$("ul.mailinglist").removeClass("disabled");
    	}
    	console.log("status: " + status);
    	checkboxes.prop('checked', status);
    	checkboxes.prop('disabled', status);
    }
    
    function initInfomailForm(){
    	if(DEBUG){ console.log("Initializing newsletter infomail form"); }
    	var button = $("#newsletter-infomail button");
    	var ajaxFile = button.data("ajax");
		var buttonClicked = false;
    	button.click(function(){
    		if(!buttonClicked){
    			$("#newsletter-infomail .info").hide();
    			buttonClicked = true;
	        	var email = $("#newsletter-infomail input#email").val();
	        	var ouname = $("#newsletter-infomail input#ouname").val();
	        	var action = $("#newsletter-infomail input#action").val();
	        	var path = $("#newsletter-infomail input#path").val();
	        	var content = $("#newsletter-infomail input#content").val();
	        	var locale = $("#newsletter-infomail input#locale").val();
	        	if(validateEmail(email)){
	        		$("#newsletter-infomail").removeClass("state-error");
	            	if(DEBUG){ console.log("E-Mail: " + email); }
	            	hideError();
	        		jQuery.ajax({
	        			method: "POST",
	        			url: ajaxFile,
	        			data: { "email": email, "ouname": ouname, "action": action, "content": content, "path": path, "loc": locale }
	        		})
	        		.done(function(text){
	        			$("#newsletter-infomail .info").empty();
	        			$("#newsletter-infomail .info").append(text);
	        			$("#newsletter-infomail .info").show();
	        		});
	        	}
	        	else{
	        		showError();
	        	}
    			setTimeout(
    					function(){ 
		        			buttonClicked = false;
		        		}, 
		        		2000
		        );
    		}
    	});
    }
    
    function showError(){
    	var form = $("#newsletter-infomail");
    	form.addClass("state-error");
    	$("#newsletter-infomail .email-error").show();
    }

    function hideError(){
    	var input = $("#newsletter-infomail input#email");
    	input.removeClass("error");
    	$("#newsletter-infomail .email-error").hide();
    }
    
    function validateEmail(email){
    	var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    	return regex.test(email);
    }
	
	return {
		init: init,
		buildFormParameters: buildFormParameters,
		validate: validate,
		selectAllMailinglists: selectAllMailinglists
	}

}(jQuery);
