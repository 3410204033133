

var Dimdi = function(jQ) {

	// DIMDI main init function!
	// Add additional modules or init calls here.
	function init() {
	
	    if (DEBUG) console.info("DIMDI init");
	
	    DimdiSliders.init();
	    DimdiNavigation.init();
	    DimdiNewsletter.init();
	    DimdiMisc.init();
	    DimdiSearch.init();
	    
	}
	
	return {
	
		init: init
	
	};

}(jQuery);