
/*
 * DIMDI
 * Small scripts that do not justify an own file.
 */

var DimdiMisc = function(jQ) {
	
	function init(){
		initDatepicker();
		initFaqDatatables();
		registerCollapsibleListeners();
		initGlossary();
		handleLinks();
		modifyTopcontrol();
		registerMarginal();
		openAccordionByAnchor();
		initFaqListeners();
		initDownloadcenter();
		initListNavClickListener();
	}
	
	function initDatepicker(){
		
		// get locale from data attribute
		var locale = $(".ap-search").data("locale");
		if(typeof locale === "undefined"){
			locale = "de";
		}
		
		$(".ap-search .input-daterange").datepicker({
			format: "dd.mm.yyyy",
			showOnFocus: false,
			language: locale
		});
		
		$(".calendarFrom").click(function(){
			$(".input-daterange .from input").datepicker("show");
		});
		$(".calendarTo").click(function(){
			$(".input-daterange .to input").datepicker("show");
		});
		
		$("#fromDateView").change(function(){
			if(!$(this).val()) $("#fromDate").val("");
		});
		$("#toDateView").change(function(){
			if(!$(this).val()) $("#toDate").val("");
		});

		$("#fromDateView").datepicker().on("changeDate", function(e){
			var newDate = e.format(undefined, "yyyy-mm-ddT00:00:00Z");
			if(DEBUG)
				console.log("from: " + newDate);
			$("#fromDate").val(newDate);
		});

		$("#toDateView").datepicker().on("changeDate", function(e){
			var newDate = e.format(undefined, "yyyy-mm-ddT00:00:00Z");
			if(DEBUG)
				console.log("to: " + newDate);
			$("#toDate").val(newDate);
		});
	}
	
	function registerCollapsibleListeners(){
		if($(".listen-collapse").length > 0){
			$(".listen-collapse .collapse").on("hide.bs.collapse", function(){
				$(this).parent().addClass("closed");
			});
			
			$(".listen-collapse .collapse").on("show.bs.collapse", function(){
				$(this).parent().removeClass("closed");
			});
		}
	}
	
	function keyboardAction(event, elem, action, info){
		var keycode = -1;
		if(event && event.type == 'keydown'){
			if(action === "searchfield"){
				if(event.keyCode){ keycode = event.keyCode; }
				else if(event.which){ keycode = event.which; }
				if(keycode != 13 && keycode != 32){
					return true;
				}
				event.preventDefault();
				elem.form.submit();
				return false;
			}
			if(action === "stagenav"){
				if(event.keyCode){ keycode = event.keyCode; }
				else if(event.which){ keycode = event.which; }
				if(keycode != 13 && keycode != 32){
					return true;
				}
				if(typeof info !== "undefined"){
					if(info === "prev"){
						$('#dimdi-revslider').revprev();
					}
					else if(info == "next"){
						$('#dimdi-revslider').revnext();
					}
				}
				else {
					DimdiSliders.changeStageSlide($(elem).index(), $(elem));
				}
			}

			if(action === "closenav"){
				if(event.keyCode){ keycode = event.keyCode; }
				else if(event.which){ keycode = event.which; }
				if(keycode != 13 && keycode != 32){
					return true;
				}
				$('button.navbar-toggle').click();
			}

			if(action === "clickDatatableRow"){
				if(event.keyCode){ keycode = event.keyCode; }
				else if(event.which){ keycode = event.which; }
				if(keycode != 13 && keycode != 32){
					return true;
				}
				$(elem).click();
			}

			if(action === "clickGlossaryFilter"){
				if(event.keyCode){ keycode = event.keyCode; }
				else if(event.which){ keycode = event.which; }
				if(keycode != 13 && keycode != 32){
					return true;
				}
				$(elem).click();
			}
		}
	}
	
	function initFaqDatatables(locale){
		
		$.fn.dataTable.moment = function ( format, locale ) {
		    var types = $.fn.dataTable.ext.type;
		 
		    // Add type detection
		    types.detect.unshift( function ( d ) {
		    	// console.log("----- isValid: " + moment( d, format, locale, true ).isValid());
		        return moment( d, format, locale, true ).isValid() ?
		            'moment-'+format :
		            null;
		    } );
		 
		    // Add sorting method - use an integer for the sorting
		    types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
		        return moment( d, format, locale, true ).unix();
		    };
		};
		
		// Init the datatable instance
		var datatables = $(".faq-datatable");
		if(datatables.length > 0){
			$("#topcontrol").text($("body").data("toptext"));
			
			datatables.each(function(){
				// first check links and add external where needed
				var $aHrefs = $(this).find("a");
				if (! Apollo.isEditMode()) {
			        $aHrefs.each(function() {

			            var $element = jQ(this);
			            var hostname = $element.prop("hostname");
			            if (hostname && hostname !== location.hostname) {
			            	if(!$element.hasClass("noicon")){
			                    $element.addClass("external");
			            	}
			                if(typeof $element.attr("title") === "undefined"){
			                	var text = $("body").data("exttitle");
			                    if (DEBUG) console.info("external link will get title attribute: " + text);
			                	$element.attr("title", text);
			                }
			            }
			        });
				}
				
				var dataLocale = $(this).data("loc");
				var dataShowAll = $(this).data("showall");
				var pagingActive = true;
				if(dataShowAll === true || dataShowAll == "true"){
					pagingActive = false;
				}
				var dtConfig = {
						"createdRow": function(row, data, dataIndex){
							$(row).attr("aria-expanded", "false");
						},
						"searching": false,
						"paging": pagingActive,
						"columnDefs": [ 
						    {"visible": false, "targets": 5},
						    {"width": "100px", "targets": 4}
						]
				}
				if(typeof dataLocale != "undefined" && dataLocale.toLowerCase() == "de"){
					dtConfig.language = $(this).data("i18n");;
				}
				
				$.fn.dataTable.moment('DD.MM.YYYY');
				
				$(this).dataTable(dtConfig);
				
				// Create a click listener for each row
				var table = $(this).DataTable();
				$(this).find("tbody").on('click', 'tr', function(){
					var data = table.row(this).data();
					if(!$(this).hasClass("open") && !$(this).hasClass("content")){
						removeFaqDiv();
						showFaqDiv(this, data[data.length - 1]);
					}
					else if($(this).hasClass("open")){
						removeFaqDiv();
					}
				});
				
			});
		}
	}
	
	function showFaqDiv(elem, content){
		var $elem = $(elem);
		$elem.addClass("open");
		$elem.attr("aria-expanded", "true");
		
		var td = $("<td>");
		var trWrapper = $("<tr>", {"class": "content"});
		var emptyTr = $("<tr>", {"class": "empty"});
		var div = $("<div>", {"class": "faqContent"});
		
		td.attr("colspan", $elem.find("td").length);
		emptyTr.append(td.clone());
		div.html(content);
		td.append(div);
		trWrapper.append(td);
		trWrapper.insertAfter($elem);
		emptyTr.insertAfter(trWrapper);
	}
	
	function removeFaqDiv(){
		$("tbody tr.empty").remove();
		$("tbody tr.content").remove();
		$("tbody tr.open").attr("aria-expanded", "false").removeClass("open");
	}
	
	function initGlossary(){
		var currChar = "none";
		var chars = [];
		
		// insert alphabet blocks between the sections
		var panels = $(".alphabet .panel");
		for(var i = 0; i < panels.length; i++){
			var elem = panels.eq(i);
			var text = elem.find(".glossary-term").text();
			var character = text.charAt(0).toUpperCase();
			if(currChar !== character){
				currChar = character;
				chars.push(character);
				var newDiv = $("<div>", {"class": "charblock", "id": "glossaryCharacter" + character});
				newDiv.text(character);
				newDiv.insertBefore(elem);
			}
		}
		
		// fill linkbar with alphabet links
		var linkbar = $(".alphabet .linkbar");
		var charblocks = $(".charblock");
		chars.sort();
		for(var i = 0; i < chars.length; i++){
		  linkbar.append(  $( "<a>", {text: chars[i], href: "#glossaryCharacter" + chars[i]} )  );
		}
		// copy the linkbar to the end of the glossary
		$(".alphabet .linkbar-bottom").append(linkbar.clone());
		
		initGlossaryListeners();
	}
	
	function initGlossaryListeners(){
		// add a click listener to every reference link so that it opens the corresponding tab
		var links = $(".ap-glossary .ap-panel.panel-group .panel-collapse a");
		for(var i = 0; i < links.length; i++){
			var elem = links.eq(i);
			var href = elem.attr("href");
			if(href != null && href.includes("#")){
				var anchor = href.substring(href.indexOf("#") + 1);
				elem.data("ref", anchor);
				elem.click(function(){
					$("#" + $(this).data("ref") + "_collapse").collapse("show");
				});
			}
		}
	}
	
	function handleLinks(){
        var $aHrefs = jQ('.area-body a');
        handleExternalLinks($aHrefs);
        handleMailtoLinks($aHrefs);
        handleDownloadLinks($aHrefs);
	}
	
    // apply "external" class to all a href links
    function handleExternalLinks(elems) {
        var $aHrefs = elems;
        if (DEBUG) console.info("a elements found in dimdi .area-body: " + $aHrefs.length);
        if (DEBUG) console.info("location.hostname is: " + location.hostname);
        if (! Apollo.isEditMode()) {
        	for(var i = 0; i < $aHrefs.length; i++){
        		var $element = $aHrefs.eq(i);
	            var hostname = $element.prop("hostname");
	            if (hostname && hostname !== location.hostname) {
	            	if(!$element.hasClass("noicon")){
	                    $element.addClass("external");
	            	}
	                if(typeof $element.attr("title") === "undefined"){
	                	var text = $("body").data("exttitle");
	                    if (DEBUG) console.info("external link will get title attribute: " + text);
	                	$element.attr("title", text);
	                }
	            }
        	}
        } else {
            if (DEBUG) console.info("skipped external a element extension because of edit mode");
        }
    }
	
    // apply mailto title attribute to all mailto links
    function handleMailtoLinks(elems) {
        var $aHrefs = elems;
        if (DEBUG) console.info("a elements found: " + $aHrefs.length);
        if (! Apollo.isEditMode()) {
        	for(var i = 0; i < $aHrefs.length; i++){
        		var $element = $aHrefs.eq(i);
                if ($element.hasClass("mail")) {
                    if(typeof $element.attr("title") === "undefined"){
                    	var text = $("body").data("mailtotitle");
                        if (DEBUG) console.info("mailto link will get title attribute: " + text);
                    	$element.attr("title", text);
                    }
                }
        	}
        } else {
            if (DEBUG) console.info("skipped mailto a element extension because of edit mode");
        }
    }
    
    // Show download icon for specific extensions by using a class attribute that triggers it
    function handleDownloadLinks(elems){
        if (! Apollo.isEditMode()) {
        	for(var i = 0; i < elems.length; i++){
        		var $element = elems.eq(i);
        		var href = $element.attr("href");
        		if(typeof href !== "undefined"){
	            	var endIndex = href.indexOf('?');
	            	if(endIndex == -1){
	            		endIndex = href.length;
	            	}
	        		var uri = href.substring(0,endIndex);
	        		var ext = uri.substring(uri.lastIndexOf('.') + 1);
	        		var whitelist = "mp4 mp3 zip pdf";
	        		var isDownload = (typeof ext !== "undefined" && ext.length > 0 && whitelist.indexOf(ext) !== -1);
		            if (isDownload) {
		            	if(!$element.hasClass("noicon")){
		                    $element.addClass("download");
		            	}
		            }
        		}
        	}
        } else {
            if (DEBUG) console.info("skipped download a element extension because of edit mode");
        }
    }
    
    function modifyTopcontrol(){
    	var toptext = $("body").data("toptext");
		var topcontrol = $("#topcontrol");
		topcontrol.text(toptext);
		topcontrol.attr("title", toptext);
		topcontrol.attr("role","button");
		topcontrol.attr("tabindex","0");
    }
    
    function registerMarginal(){
    	var marginal = $(".marginal");
    	if(marginal.length > 0){
    		$(".dimdi-content-wrapper").addClass("has-marginal");
    	}
    }
    
    function toClipboard(elem, childselector){
    	if(typeof childselector !== 'undefined'){
    		elem = $(elem).find(childselector);
    	}
    	elem.select();
    	document.execCommand("copy");
    }
    
    function openAccordionByAnchor(){
    	var location = document.location;
    	if(location.hash.length > 0){
    		var hash = location.hash;
    		var elem = $(hash).parent();
    		if(elem.hasClass("panel-collapse")){
    			elem.collapse('show');
    		}
    	}
    }
	
	function initFaqListeners(){
		// add a click listener to every reference link so that it opens the corresponding tab
		var links = $(".dimdi-faq .panel-collapse a");
		for(var i = 0; i < links.length; i++){
			var elem = links.eq(i);
			var href = elem.attr("href");
			if(href != null && href.includes("#")){
				var anchor = href.substring(href.indexOf("#") + 1);
				elem.data("ref", anchor);
				elem.click(function(){
					$("#" + $(this).data("ref")).find(".panel-collapse").collapse("show");
				});
			}
		}
	}
	
	function showDownloads(elem, start){
		var dlcenterMain = $("#dlcenter-main");
		var rowcount = dlcenterMain.data("rows");
		
		if(typeof start === "undefined"){
			start = 0;
			$("#navlist .showndir").removeClass("showndir");
		}
		
		$(elem).addClass("showndir");
		var folder = $(elem).data("folder");
		var sitepath = $(elem).data("sitepath");
		var locale = $(elem).data("locale");
		var dlentries = document.getElementById("dlentries");
		var $dlentries = $(dlentries);
		var ajaxfile = $dlentries.data("ajax");
		$dlentries.html("");
		
		jQuery.ajax({
			method: "POST",
			url: ajaxfile,
			data: { "folder": folder, "sitepath": sitepath, "loc": locale, "rows": rowcount, "start": start }
		})
		.done(function(result){
			$dlentries.html(result);

	        var $aHrefs = $dlentries.find('a');
	        
	        handleExternalLinks($aHrefs);
	        handleMailtoLinks($aHrefs);
		});
	}
	
	function initDownloadcenter(){
		var dlcenterMain = $("#dlcenter-main");
		if(dlcenterMain.length > 0){
			// insert dlcenter with AJAX
			var ajaxfile = dlcenterMain.data("ajax");
			var folder = dlcenterMain.data("folder");
			var sitepath = dlcenterMain.data("sitepath");
			var locale = dlcenterMain.data("locale");
			var dirparam = dlcenterMain.data("dir");
			jQuery.ajax({
				method: "POST",
				url: ajaxfile,
				data: { "folder": folder, "sitepath": sitepath, "loc": locale, "dir": dirparam }
			}).done(function(result){
				// insert dlcenter and load startfolder content
				dlcenterMain.html(result);

				var startElem = dlcenterMain.find("a[data-start]");
				var elem;
				if(startElem.length > 0){
					elem = startElem;
				}
				else {
					elem = $(".sidebar-nav.list-group li a");
				}
				if(elem.length > 0){
					showDownloads(elem.get(0));
				}
				
				dlcenterScroll();
				dlcenterHandles();
			});
		}
	}
	
	function dlcenterScroll(){
		var dlcenter = document.getElementById('dlcenter');
		if(typeof dlcenter !== "undefined"){
			var as = $("#navlist > li > a");
			for(var i = 0; i < as.length; i++){
				as.eq(i).click(function(){
					if(!isElementInViewport(dlcenter)){
						dlcenter.scrollIntoView();
					}
				});
			}
		}
	}
	
	function dlcenterHandles(){
		var handles = document.querySelectorAll(".dlcenter-handle");
		for(var i = 0; i < handles.length; i++){
			tempHandle = handles[i];
			var hrefAttr = tempHandle.getAttribute("href");
			var dataRef = tempHandle.getAttribute("data-navhandle");
			var elem = document.getElementById(dataRef);
			elem.setAttribute("href", hrefAttr);
			elem.setAttribute("data-toggle", "collapse");
		}
	}
	
	function isElementInViewport (el) {
	    if (typeof jQuery === "function" && el instanceof jQuery) {
	        el = el[0];
	    }
	    var rect = el.getBoundingClientRect();
	    return (
	        rect.top >= 0 &&
	        rect.left >= 0 &&
	        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
	        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
	    );
	}
	
	function initListNavClickListener(){

		window.addEventListener('list-pagination-appended', function (e) {
			var targetElem = $(".ap-list-entries");
			var listNavs = $(".list-append-position ul.pagination li a");
			if(targetElem.length > 0){
				for(var i = 0; i < listNavs.length; i++){
					listNavs.eq(i).click(function(){
						targetElem.get(0).scrollIntoView(); 
					});
				}
			}
		});
	}
	
	return {
		init: init,
		toClipboard: toClipboard,
		keyboard: keyboardAction,
		showDownloads: showDownloads
	}

}(jQuery);
