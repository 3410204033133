
/*
 * DIMDI
 * Scripts for slider initialization and modification
 */

var DimdiSliders = function(jQ) {
	
    /**
     * Simple sliders
     */
    function initStage($sliders, extpath) {
    	
        // determine scroll bar width because RS does not consider this when using responsive levels
        var w1 = window.innerWidth;
        var w2 = jQ('body').innerWidth();
        var scrollWidth = w1 - w2;
    	
    	$sliders.each(function(){
            var $slider = jQ(this);
            var extPath = $slider.data("ext");
            var autoplay = $slider.data("autoplay");
            var stopAfterLoops = -1;
            var stopAtSlide = -1;
            if(!autoplay){
            	stopAfterLoops = 0;
            	stopAtSlide = 1;
            }
            if(DEBUG){
                console.log("Revslider ExtPath: " + extPath);
            }
            initCustomNav($slider);
            $slider.show().revolution({
    			delay: 7000,
    			sliderLayout: 'auto',
    			responsiveLevels:[4000,1280 - scrollWidth,992 - scrollWidth,768 - scrollWidth],									
    			visibilityLevels:[4000,1280 - scrollWidth,992 - scrollWidth,768 - scrollWidth],
    			gridwidth: [832,522,750,750],
    			gridheight: [335,335,335,335],	
    			autoHeight: "on",
    			disableProgressBar: "on",
    			stopAtSlide: stopAtSlide,
    			stopAfterLoops: stopAfterLoops,
    		
    			navigation: {
    				tabs: {
    					enable: false,
    				}
    		
    			},
    		    extensions: extPath
    		});
            
        	// bind listener for automatic slide changes
        	$slider.bind("revolution.slide.onbeforeswap", function(e, data) {
        		var slideindex = data.nextslide.index();
        		if(slideindex >= 0){
        			changeActiveNavElement(slideindex);
            		updateSmallNav(slideindex);
        		}
        		else {
        			$slider.revshowslide(1);
        			changeActiveNavElement(0);
        		}
        	});

        	$slider.bind("revolution.slide.onloaded", function(e){
        		showNav();
        	});
    	});
    }
    
    /** This copies the texts from the list elements into the slider navigation **/
    function initCustomNav($slider){
    	var nav = $slider.parent().parent().find(".dimdi-stage-nav");
    	if(nav.data("init") == "true"){
    		var listItems = $slider.find("li");
        	var tab = nav.find(".tabs .tab");
        	var tabContainer = nav.find(".tabs");
        	
        	// copy tab 3 times to have 4 of them
        	for(var i = 0; i < 3; i++){
            	tab.clone().appendTo(tabContainer);
        	}

        	// copy title and teaser-text into navigation
        	var tabs = nav.find(".tabs .tab");
        	for(var i = 0; i < listItems.length; i++){
            	if(i == 0){ 
            		var activeTab = tabs.eq(0);
            		activeTab.addClass("selected"); 
            		textCol = activeTab.find(".text");
            		textCol.removeClass("col-xs-12");
            		textCol.addClass("col-xs-9");
            	}
        		var title = listItems.eq(i).data("title");
        		var teasertext = listItems.eq(i).data("param1");
        		var date = listItems.eq(i).data("param2");
        		tabs.eq(i).find(".tab-title").text(title);
        		tabs.eq(i).find(".tab-teaser").text(teasertext);
        		tabs.eq(i).find(".tab-date").text(date);
        	}
    	}
    	
    }

	function changeStageSlide(index, elem){
		if($('#dimdi-revslider').revcurrentslide() != index + 1 && !elem.hasClass("selected")){
			$('#dimdi-revslider').revshowslide(index + 1);
			changeActiveNavElement(index);
		}
	}
	
	function changeActiveNavElement(index){
		// change attributes on currently active tab
		var currActive = $('.dimdi-stage-nav .tab').filter(".selected");
		currActive.removeClass("selected");
		var textCol = currActive.find(".text");
		textCol.removeClass("col-xs-9");
		textCol.addClass("col-xs-12");

		// change attributes on the now active tab
		var newActive = $('.dimdi-stage-nav .tab').eq(index);
		newActive.addClass("selected");
		textCol = newActive.find(".text");
		textCol.removeClass("col-xs-12");
		textCol.addClass("col-xs-9");
	};
	
	function showNav(){
		var currActive = $('.dimdi-stage-nav').show();
	}
	
	function updateSmallNav(index) {
		var nav = $(".dimdi-stage-nav-small");
		nav.find(".count").text((index + 1) + "/4");
		var tabs = $(".dimdi-stage-nav-small .tabs .tab");
		tabs.hide();
		tabs.eq(index).show();
	}
	
	function initCarousels(carousels){
		for(var i = 0; i < carousels.length; i++){
			var carousel = carousels.eq(i);
			carousel.owlCarousel(getCarouselParams(carousel));
		}
	}
	
	function getCarouselParams(carousel){
		var respOption = carousel.data("resp");
		
		var respValues = [
		    {
		    	0: {
		    	    items: 1
		    	},
		    	768: {
		    		items: 2
		    	},
		    	992: {
		    		items: carousel.data("items")
		    	}
		    }
		];
		
		return {
			margin: 16,
			navText: ["",""],
			items: carousel.data("items"),
			loop: carousel.data("loop"),
			mouseDrag: carousel.data("mousedrag"),
			touchDrag: carousel.data("touchdrag"),
			nav: carousel.data("nav"),
			dots: carousel.data("dots"),
			dotsEach: carousel.data("dotseach"),
			autoplay: carousel.data("autoplay"),
			autoplayTimeout: carousel.data("autotime"),
			rewind: carousel.data("rewind"),
			autoplayHoverPause: carousel.data("hoverpause"),
			responsiveRefreshRate: 250,
			responsive: respValues[respOption]
		}
	}
	
	function init(){
		initStage($("#dimdi-revslider"));
		initCarousels($(".owl-carousel"));
	}
	
	return {
		init: init,
		changeStageSlide: changeStageSlide
	}

}(jQuery);
